import React from 'react'
import Layout from '../components/shared/Layout'
import SEO from '../components/seo'

const Typography = () => {
return (
    <Layout>

        <SEO title="Typografi" />
        <div className="wrap" role="document">
            <div className="content">

                <main className="main" id="main">
                    <header className="header">
                        <div className="container">
                            <div className="header__caption">
                                <h1>Typografi</h1>
                            </div>
                        </div>
                    </header>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="submenu">
                                    <ul className="submenu__list">
                                        <li className="submenu__item"><a href="#heading">Overskrift</a></li>
                                        <li className="submenu__item"><a href="#body-text">Brødtekst</a></li>
                                        <li className="submenu__item"><a href="#digital-text">Digital tekst</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-9">

                                <p className="lead ingress">
                                    Kjedens visuelle identitet består også av spesifikke fonter. De er rene, moderne, harmonerer godt til hverandre og vil skape en god identitet til Saga KL.</p>

                                <hr/>

                                <div id="heading" className="section">
                                    <h2>Overskifter</h2>
                                    <p>
                                        Dosis er rakrygget og elegant, og den står
                                        ut i mengden. Brukes til overskrifter på alt 
                                        trykket materiell. Vi brukes skriftsnittet 
                                        Dosis Medium.
                                    </p>
                                    <a href="https://fonts.google.com/specimen/Dosis?selection.family=Dosis" className="btn button button--download mx-auto">Se mer av Dosis</a>
                                    <img src="../img/sections/fonter/font_1.jpg" alt="Saga KL Profilmanual Typografi Dosis font 900x600" className="img-fluid"/>
                                </div>

                                <hr/>

                                <div id="body-text" className="section">
                                    <h2>Brødtekst++</h2>
                                    <p>
                                        Til brødtekst, ingresser, bildetekst og 
                                        underoverskrifter brukes varianter av 
                                        Open Sans. Dette er en hyggelig, tidsriktig 
                                        og lettlest font. Open Sans Semibold til ingresser
                                        og Open Sans Light til brødtekst.
                                    </p>
                                    <a href="https://fonts.google.com/specimen/Open+Sans?selection.family=Open+Sans" className="btn button button--download mx-auto">Se mer av Open Sans</a>
                                    <img src="../img/sections/fonter/font_2.jpg" alt="Saga KL Profilmanual Typografi Open Sans font 900x600" className="img-fluid"/>
                                </div>
                                <hr/>

                                <div id="digital-text" className="section">
                                    <h2>Digital tekst</h2>
                                    <p>
                                        Tekst til MS Office-programmer som Word og 
                                        Powerpoint er valgt en font som kan brukes 
                                        på alle plattformer. Her brukes Calibri.
                                    </p>
                                    <p>Er forhåndsinstallert på alle Windows maskiner</p>
                                    <img src="../img/sections/fonter/font_3.jpg" alt="Saga KL Profilmanual Typografi Calibri font 900x600" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>

            </div>
        </div>

    </Layout>
)
}

export default Typography